import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"
import { Link } from 'gatsby'
import PlaceholderPic from './image'

const Columns = styled.div`
  margin: 0;
    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(1,1fr);
    }
  

  h2 {
    grid-column:1/-1;
    margin-bottom:2em;
    text-transform:uppercase;
  }
  
  div {
    align-self: center;
    
  }
  
`

const Entry = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap:4em;
    margin:2em 0;

    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(1,1fr);
    }
  
  
`



const Teammates = ({ teammates }) => (
  
  <Columns>
    {teammates.map(leadership => (
        <Entry >
           <img src={leadership.image} />
           <div>
            <h3>{leadership.name}</h3>
            <p>{leadership.text}</p>
           </div>
        </Entry>
    ))}

  </Columns>
)

Teammates.propTypes = {
    teammates: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

export default Teammates