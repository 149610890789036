import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Teammates from '../components/teammates'

// import posed from 'react-pose';

// const Box = posed.div({
//   visible: {
//     y: 0,
//     opacity: 1,
//     delay: 300,
//     transition: {
//   duration: 400,
//   ease: 'linear'
// }
//   },
//   hidden: {
//     y: 50,
//     opacity: 0,
//     transition: { duration: 150 }
//   }
// });



const BodyContent = styled.div`
  padding:0 2em;
  max-width:1120px;
  margin:0 auto;

    h1 {
        margin:1em 0;
    }
    p {
        margin: 1em 0;
    }
`

const IntroSection = styled.div`
  margin-bottom:3em;
  padding: 0 0 1em;
  border-bottom:1px solid;
`

class AboutPageTemplate extends React.Component {
  // state = { isVisible: false };

  // componentDidMount() {
  //   setInterval(() => {
  //     this.setState({
  //       isVisible: !this.state.isVisible
  //     });
  //   }, 2000);
  // }

  constructor(props) {
    super(props)
    this.transitionEnd = this.transitionEnd.bind(this)
    this.mountStyle = this.mountStyle.bind(this)
    this.unMountStyle = this.unMountStyle.bind(this)
    this.state ={ //base css
      show: true,
      style :{
        opacity: 0,
        transition: 'all 2s ease',
      }
    }
  }
  
  componentWillReceiveProps(newProps) { // check for the mounted props
    if(!newProps.mounted)
      return this.unMountStyle() // call outro animation when mounted prop is false
    this.setState({ // remount the node when the mounted prop is true
      show: true
    })
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  unMountStyle() { // css for unmount animation
    this.setState({
      style: {
        opacity: 0,
        transition: 'all 1s ease',
      }
    })
  }
  
  mountStyle() { // css for mount animation
    this.setState({
      style: {
        opacity: 1,
        transition: 'all 1s ease',
      }
    })
  }
  
  componentDidMount(){
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  transitionEnd(){
    if(!this.props.mounted){ // remove the node on transition end when the mounted prop is false
      this.setState({
        show: false
      })
    }
  }
  

  render() {
    const { isVisible } = this.state;
    const post = this.props.data.markdownRemark
    const teammates = post.frontmatter.team.teammates 
    const siteTitle = this.props.data.site.siteMetadata.title

    
    return (
      <Layout 
      location={this.props.location} title={siteTitle}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

{/* <Box className="box" pose={isVisible ? 'visible' : 'hidden'} > */}
        <BodyContent style={this.state.style} onTransitionEnd={this.transitionEnd}>
        <h1>{post.frontmatter.title}</h1>
      
        <IntroSection dangerouslySetInnerHTML={{ __html: post.html }} />
        <h2>The Team</h2>
        <Teammates teammates={teammates} />
        </BodyContent>
        {/* </Box> */}
      </Layout>
    )
  }
}

export default AboutPageTemplate

export const pageQuery = graphql`
  query AboutPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        team {
          teammates {
            name
            text
            image 
          }
        }
      }
    }
  }
`
